.datasheet {
    padding: 96px 16px 24px 16px;
    display: flex;
    flex-direction: column;

    > a {
        text-decoration: none;
    }

    &__back-button {
        height: 32px;
        padding: 0 16px;
        display: flex;
        align-self: flex-start;
        align-items: center;
        text-transform:uppercase;
        background: $baseBlue;
        color: white;
        font-family: $absolutBold;
        font-size: 16px;
        border-radius: 16px;
        margin-bottom: 16px;
        

        img {
            height: 16px;
            width: auto;
            margin-right: 16px;
        }

        span {
            padding-top: 3px;
            margin-right: 8px;
        }

    }

    &__material-name {
        font-family: $absolutBold;
        font-size: 32px;
        margin-bottom: 16px;
        color: $primary;
    }

    &__description {
        font-family: $absolutRegular;
        font-size: 14px;
        color: $grey;
    }

    &__facts {
        display: flex;
        flex-direction: column;
    }

    &__fact {
        display: flex;
        flex-direction: column;
        margin: 16px 0;

        &--condensed {
            margin: 8px 0;
        }

        &--extra-condensed {
            margin: 4px 0;
        }

        &--row {
            flex-direction: row;
        }

    }

    &__fact-bold {
        font-family: $absolutBold;
        font-size: 14px;
        color: $primary;
        text-transform: uppercase;
        margin-bottom: 8px;

        &--space-right {
            margin-right: 8px;
        }
    }

    &__fact-small {
        font-family: $absolutRegular;
        font-size: 14px;
        color: $grey;

        &--space-right {
            margin-right: 8px;
        }
    }

    &__composition-title {
        font-family: $absolutBold;
        font-size: 14px;
        color: $primary;
        text-transform: uppercase;
        margin-bottom: 8px;
    }

    &__composition-table {
        width: calc(100% - 32px);
        margin-top: 16px;

        th, td {
            height: 32px;
        }

        th {
            font-family: $absolutBold;
            color: $primary;
        }

        thead tr {
            border-bottom: 1px solid $primary;
        }

        td {
            vertical-align: middle;
            padding-left: 8px;
            font-family: $absolutRegular;
            color: $primary;
        }

    }

}