.hero-material {
    display: flex;
    flex-direction: column;
    align-items: flex-end;    
    position: relative;
    padding: 16px;
    max-width: 1024px;


    &__mobile-background {
        display: none;
    }

    &__title {
        margin-top: 32px;
        width: auto;
        font-family: 'AbsolutBold', Arial, Helvetica, sans-serif;
        font-size: 40px;
        color: white;
        width: 250px;
    }

    &__description {
        width: 250px;
        font-family: 'AbsolutRegular', Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: white;
    }

    &__bottom-section {
        display: flex;
        width: 100%;
        margin-top: 16px;
    }
    
    &__open-datasheet-button {
        height: 32px;
        border-radius: 16px;
        background: white;
        color: $baseBlue;
        font-family: $absolutBold;
        text-transform: uppercase;
        padding: 0 24px;
        margin-top: 48px;
        border: none;
        outline: none;
    }

    @include for-phone-only {
        &__text {
            .hero-material__open-datasheet-button {
                display: none;
            }
        }

        &__mobile-background {
            display: flex;
            position: absolute;
            top: 0; left: 0;
            height: 100%; width: 100%;
            object-fit: cover;
            object-position: 0 0;
            z-index: -1;
        }

        &__bottom-section {
            > img {
                height: 225px;
            }

            button > img {
                display: none;
            }
        }
    }

    @include for-tablet-portrait-up {
        flex-direction: row;
        margin: auto 0;
        align-items: center;

        &__text {
            order: 2;

            .hero-material__open-datasheet-button {
                background: transparent;
                color: white;

                span {
                    margin-right: 16px;
                }
            }
        }

        &__bottom-section {

            > img {
                height: 300px;
                width: auto;
            }

            .hero-material__open-datasheet-button {
                display: none;
            }
        }


    }
}