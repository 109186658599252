section {
    min-height: 100vh;
    display: flex;
    padding: 88px 0 24px 16px;
    width: 100%;

    > h1 {
        font-family: 'AbsolutBold', Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 22px;
        color: $primary;
        margin-bottom: 16px;
    }
}