#home {
    flex-direction: column;
    
    background-size: 100% 100%;

    @include for-phone-only {
        background-image: none !important;
    }

    @include for-tablet-portrait-up {
        justify-content: center;
    }

    .arrow-down {
        margin: auto auto 16px auto;
    }
}

.home-container {
    display: flex;
    max-width: 1024px;
    margin: 0 auto;
    flex-grow: 1;

    > h1 {
        font-size: 32px;
        font-family: $absolutBold;
        color: $primary;
    }

    @include for-phone-only {
        flex-direction: column;

        > h1 {
            font-size: 26px;
            width: 75%;
            margin-bottom: 24px;
        }
    }

    @include for-tablet-portrait-up {
        > h1 {
            display: flex;
            align-items: center;
            margin-left: 32px;
        }
    }
    
}