.material {
    width: 100%;
    position: relative;
    margin: 24px 0;
    display: flex;
    align-items: center;
    max-width: 400px;

    &__background {
        z-index: -1;
        position: absolute;
        top: 0; left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    &__image {
        padding: 16px;
        height: 200px;
        width: 175px;
        object-fit: contain;
        transform: translateX(-16px);
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: translateX(-32px);

        button {
            height: 24px;
            border-radius: 14px;
            background: white;
            color: $baseBlue;
            font-family: $absolutBold;
            font-size: 10px;
            text-transform: uppercase;
            padding: 0 16px;
            margin-top: 24px;
            border: none;
            outline: none;
        }
    }

    &__short-name {
        font-size: 24px;
        font-family: $absolutBold;
        color: white;
    }

    &__name {
        font-size: 10px;
        font-family: $absolutBold;
        color: white;
        text-transform: uppercase;
    }
}

.materials {
    display: flex;
    flex-direction: column;

    @include for-tablet-portrait-up {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}