section#contact-us {
    background: $baseBlue;
    position: relative;
    padding: calc(24.5vw + 32px) 24px 24px 24px;
    display: flex;
    flex-direction: column;

    h1 {
        color: white;
        font-family: $absolutBold;
        font-size: 22px;
        margin-bottom: 32px;
    }
}

.contact-form {
    

    form {
        display: flex;
        flex-direction: column;
    }

    &__send-button {
        height: 32px;
        align-self: center;
        padding: 8px 32px;
        font-size: 16px;
        text-transform: uppercase;
        border-radius: 16px;
        font-family: $absolutBold;
        display: flex;
        vertical-align: middle;
        color: $baseBlue;

    }
}

.contact-us-header {
    background: white;
    width: 100vw;
    height: 24.5vw;
    object-fit: cover;
    object-position: 0 0;
    position: absolute;
    top: 0; left: 0;
}

.input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 16px 0 24px 0;
    height: 32px;
    position: relative;

    &--textarea {
        height: 96px;
    }

    label {
        font-family: $absolutRegular;
        font-size: 16px;
        color: rgba(255, 255, 255, .5);
        position: absolute; 
        left: 8px; top: 8px;
        transition: transform .15s ease-in-out;
    }

    input {
        height: 100%;
    }

    input, textarea {
        background: transparent;
        outline: none;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, .5);
        color: white;
        font-size: 16px;

        &:focus {
            border-bottom: 1px solid rgba(255, 255, 255, 1);

            ~ label {
                top: 0px;
                transform: translateY(-100%) translateX(-10px) scale(.8);
                color: white;
            }
        }
    }
}

