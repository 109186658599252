.navbar {
    height: 72px;
    width: 100vw;
    position: fixed;
    top: 0; 
    left: 0;
    background: white;
    display: flex;
    align-items: center;
    padding: 0 16px;
    z-index: 1;


    &__items {
        display: flex;
        margin-left: auto;
        font-family: 'Absolut-Bold', Helvetica, sans-serif;


        @include for-phone-only {
            display: none;
        }

        > a {
            text-decoration: none;
        }
    }


    &__item {
        margin-right: 16px;
        color: $baseBlue;
        

    }

    &__open-mobile-menu-button {
        margin-left: auto;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;


        img {
            height: 17px;
            width: 17px;
        }

        @include for-tablet-portrait-up {
            display: none;
        }
    }

    @include for-tablet-portrait-up {
        background: rgba(255, 255, 255, .5);
    }
}