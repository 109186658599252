#our-materials {
    display: flex;
    flex-direction: column;
    width: calc(100% - 16px);

    > h1 {
        text-align: center;
    }

    > p {
        text-align: center;
        color: $secondary;
        font-family: $absolutRegular;
        padding: 0 16px 24px 16px;
    }

    @include for-tablet-portrait-up {
        min-height: auto;
    }
}