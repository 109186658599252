@font-face {
    font-family: 'AbsolutBold';
    src: url('./fonts/Absolut-Bold.otf');
}

@font-face {
    font-family: 'AbsolutRegular';
    src: url('./fonts/Absolut-Regular.otf');
}


@import './scss/reset';
@import './scss/variables';
@import './scss/mixins';
@import './scss/general';
@import './scss/navbar';
@import './scss/section';
@import './scss/footer';

@import './scss/home';
@import './scss/our-materials';
@import './scss/contact-us';

@import './scss/hero-material';
@import './scss/material';
@import './scss/datasheet';