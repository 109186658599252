.footer {
    background: $darkBlue;
    width: 100vw;
    height: auto;
    color: white;
    display: flex;
    font-family: $absolutBold;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    padding: 24px 16px;

    &__content {
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 32px);
        width: 992px;

        @include for-tablet-portrait-up {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__disclaimer {
        border-top: 1px solid rgba(255, 255, 255, 1);
        padding-top: 24px;
        margin-top: 16px;
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.footer-item {
    display: flex;
    flex-direction: column;
    margin: 8px 0 16px 0;

    @include for-tablet-portrait-up {
        margin: 8px 16px 16px 16px;
    }

    &__label {
        font-family: $absolutBold;
        font-size: 12px;
        color: white;
        margin-bottom: 8px;
    }

    &__text {
        font-family: $absolutRegular;
        font-size: 14px;
        color: white;

        > a {
            color: white;
        }
    }
}
